<template>
	<div class="box">
		<div class="row">
			<div class="col-12">
				<div class="form-group">
					<label for="sire">{{ $t('import.numero_sire') }}</label>
					<textarea id="sire" class="form-control" rows="10" v-model="sire"></textarea>
					<b-form-checkbox id="stallion" v-model="stallion" class="mt-2"><label for="stallion">{{ $t("horse.all_male_reproducteur") }}</label></b-form-checkbox>
				</div>

                <div class="text-center">
                	<b-button variant="primary" class="rounded-pill" @click="importSire">
                       <font-awesome-icon v-if="loading" :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t('import.importer') }}
                    </b-button>
                </div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import Horse from "@/mixins/Horse.js"
import Common from '@/assets/js/common.js'
import Shutter from "@/mixins/Shutter.js"

export default {
	name: 'ImportHorse',
	mixins: [Horse, Shutter],
	props: ['code_onboarding'],
	data () {
		return {
			sire: "",
			loading: false,
			stallion: false
		}
	},

	methods: {
		async importSire() {
			this.loading = true

			// je récupère les SIRE séparés par des retours à la ligne puis je supprime les lignes vides
			let sire_tab = this.sire.split('\n')
			sire_tab = sire_tab.filter(sire => (!!sire))

			let horse_ids = []
			await Common.asyncForEach(sire_tab, async (sire) => {
				const horse = await this.addHorseBySire(sire, + this.stallion)
				if (horse.retour && horse.retour.id) horse_ids.push(horse.retour.id) 
			})

			this.sire = ''
			
			if(this.code_onboarding) {
				const params =  {
					code: 'horse_create',
					done: 1,
					skip: 0
				}
				
				this.ok(params)
				this.shutterPanel().close(this.code_onboarding)
			}
			
			await this.$sync.force(true)
			this.loading = false
			if (horse_ids.length > 0){
				this.successToast("toast.info_modif_succes")
				this.$router.push({ name: 'postImportHorseList', params: { horse_ids: horse_ids }})
			} else {
				this.failureToast("toast.info_horse_ajout_failed")
			}
		},
	}
}

</script>